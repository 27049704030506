import { useState } from "react";

import {
    Box,
    Button,
    Form,
    FormField,
    Input,
    Modal,
    SpaceBetween
} from "@amzn/awsui-components-react";

import { sendRejectionRequests } from "../../helpers/repos/repoRequests"; // is this right?
import { FlashbarPublisher } from "../FlashbarPublisher";

export const RejectRepoModal = ({ isVisible, setIsVisible, refreshRepoRequestList, makeFlashBar, selectedRequests }) => {

    const [rejectionReason, setRejectionReason] = useState("");
    const [rejectionReasonErrorText, setRejectionReasonErrorText] = useState("");
    const [loading, setLoading] = useState(false);

    const resetFormAndHideModal = () => {
        setRejectionReasonErrorText("");
        setRejectionReason("");
        setIsVisible(false);
    }


    const rejectRepoRequests = async (selectedRequests, rejectionReason) => {
        if (rejectionReason.length > 250) {
            setRejectionReasonErrorText("Rejection Reason character length must be less than or equal to 250.");
            return;
        }
        if (rejectionReason.length == 0) {
            setRejectionReasonErrorText("Rejection Reason must be provided.");
            return;
        }

        setLoading(true);
        const failedRequests = await sendRejectionRequests(selectedRequests, rejectionReason);
        setLoading(false);
        resetFormAndHideModal();
        if (failedRequests.length > 0) {
            FlashbarPublisher.setItems([{
                id: "add_rejection_failure",
                type: "error",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: makeFlashBar(`Failed to reject ${failedRequests.length} request${failedRequests.length > 1 ? 's' : ''}. Please try again.`, failedRequests)
            }]);
        } else {
            console.log(`length: ${selectedRequests.length}`);
            FlashbarPublisher.setItems([{
                id: "add_rejection_success",
                type: "success",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: `Repository request${selectedRequests.length > 1 ? 's were' : ' was'} rejected successfully.`
            }]);
        }

        try {
            await refreshRepoRequestList();
        } catch (e) {
            // Display refresh flash bar error
            FlashbarPublisher.pushItem([{
                id: "add_refresh_failure",
                type: "error",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: "Failed to refresh request table."
            }]);
        }
    }

    return (
        <Modal
            data-testid="reject-repo-modal"
            onDismiss={() => { resetFormAndHideModal() }}
            visible={isVisible}
            header="Reject Repo Request"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            data-testid={"cancel-reject-repo-modal-button"}
                            variant="link"
                            onClick={() => { resetFormAndHideModal() }}
                        >
                            Cancel
                        </Button>
                        <Button
                            data-testid={"reject-repo-modal-button"}
                            variant="primary"
                            onClick={async () => { await rejectRepoRequests(selectedRequests, rejectionReason) }}
                            loading={loading}
                        >
                            Reject
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <form onSubmit={event => event.preventDefault()}>
                <Form>
                    <SpaceBetween size="l" direction="vertical">
                        <FormField
                            data-testid={"reject-repo-reason-form-field"}
                            label="Reject Reason"
                            errorText={rejectionReasonErrorText}
                            description={`Rejection reason must be provided and be less than 250 characters.`}
                        >
                            <Input
                                onChange={({ detail }) => {
                                    setRejectionReason(detail.value);
                                    setRejectionReasonErrorText("");
                                }}
                                value={rejectionReason}
                                placeholder="Reject Reason"
                                type="text"
                                inputMode="text"
                            />
                        </FormField>
                    </SpaceBetween>
                </Form>
            </form>
        </Modal>
    );
};