
import {
    Box,
    Button,
    Form,
    Modal,
    SpaceBetween
} from "@amzn/awsui-components-react";

import { useState } from "react";
import { getRepoAcknowledgementContent } from "../../core/constants";
import { sendApprovalRequests } from "../../helpers/repos/repoRequests"; // is this right?
import { FlashbarPublisher } from "../FlashbarPublisher";

export const ApproveRequestModal = ({ isVisible, setIsVisible, refreshRepoRequestList, selectedRequests, makeFlashBar, setSearch, search, managers }) => {

    const [loading, setLoading] = useState(false);

    const resetFormAndHideModal = () => {
        setIsVisible(false);
    }

    const approveRepoRequests = async () => {
        setLoading(true);
        const failedRequests = await sendApprovalRequests(selectedRequests);
        setLoading(false);
        resetFormAndHideModal();
        if (failedRequests.length > 0) {
            FlashbarPublisher.setItems([{
                id: "add_approval_failure",
                type: "error",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: makeFlashBar(`Failed to approve ${failedRequests.length} request${failedRequests.length > 1 ? 's' : ''}. Please try again.`, failedRequests)
            }]);
        } else {
            FlashbarPublisher.setItems([{
                id: "add_approval_success",
                type: "success",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: `Repository request${selectedRequests.length > 1 ? 's have' : ' has'} been approved and added to the repository allow list. The repositor${selectedRequests.length > 1 ? 'ies are' : 'y is'} now available for developers to push code to.`
            }]);

            const newSearchText = ""; // change the search state so the updateSearchResults function in repositories-ui/RepoSearchTable.jsx will be triggered as effect
            //Currently the refresh functionality is build in to changing the search results. 
            //Refresh button on UI also makes a call to updateSearchResults to achieve refresh functionality
            //After discussion during standup, team was okay with going with the approach of temporarily modifying the search text to invoke a refresh
            const newSearch = { newSearchText, managers };
            setSearch(newSearch);
            setSearch(search); // preserve the original search text
        }

        try {
            await refreshRepoRequestList();
        } catch (e) {
            // Display refresh flash bar error
            FlashbarPublisher.pushItem([{
                id: "add_refresh_failure",
                type: "error",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: "Failed to refresh request table."
            }]);
        }
    }

    return (
        <Modal
            data-testid="approve-repo-modal"
            onDismiss={() => { setIsVisible(false) }}
            visible={isVisible}
            header="Approve repo request"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            data-testid={"cancel-approve-repo-modal-button"}
                            variant="link"
                            onClick={() => { setIsVisible(false) }}
                        >
                            Cancel
                        </Button>
                        <Button
                            data-testid={"approve-repo-modal-button"}
                            variant="primary"
                            onClick={async () => { await approveRepoRequests(selectedRequests) }}
                            loading={loading}
                        >
                            Acknowledge and approve
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <form onSubmit={event => event.preventDefault()}>
                <Form>
                    {getRepoAcknowledgementContent(selectedRequests[0]?.type)}
                </Form>
            </form>
        </Modal>
    );
};