import { Box, Link } from "@amzn/awsui-components-react";

export const RepoType = {
    OpenSource: "OpenSource",
    OpenSourceSimpleContribution: "OpenSourceSimpleContribution",
    CustomerContract: "CustomerContract",
    Other: "Other"
}

//map enum to human readable string
export const renderedRepoType = (repo) => {
    let type;
    //repo.repoType is used by repo allow list entries, repo.type is used by repo request entries
    switch (repo.repoType || repo.type) {
        case RepoType.OpenSource:
            type = "Open Source";
            break;
        case RepoType.OpenSourceSimpleContribution:
            type = "Open Source Simple Contribution";
            break;
        case RepoType.CustomerContract:
            type = "Customer Contract";
            break;
        case RepoType.Other:
        default:
            type = "Other";
            break;
    }

    if (repo.reason) {
        type += `: ${repo.reason}`;
    }

    return type;
}

export const getRepoAcknowledgementContent = (requestType) => {
    switch (requestType) {
        case RepoType.OpenSourceSimpleContribution:
            return <Box>
                I acknowledge that this repo is a part of an open source simple contributon and the requester's work meets the requirements in the{' '}
                <Link
                    external
                    href="https://w.amazon.com/bin/view/Open_Source/Distributions/Simple_Distributions/"
                    variant="primary"
                >
                    Simple Contributions guidance
                </Link>.
            </Box>
        case RepoType.CustomerContract:
            return <Box>
                I acknowledge that this repo is required by customers/partners as part of an agreement/engagement, and the requester's work meets the requirements described in{' '}
                <Link
                    external
                    href="https://console.harmony.a2z.com/engsec-docs/Proserve/sharing-code-with-customers"
                    variant="primary"
                >
                    Sharing Source Code with Customers
                </Link>.
            </Box>
        case RepoType.Other:
        default:
            return <Box>
                I acknowledge that this repo has a <strong>justified reason even though it is outside of commonly approved business use cases</strong>, such as Open Source Program Office (OSPO) managed GitHub repos, Simple Contributions, and Sharing Code with Customers.<br /><br /> I have also verified that the content being pushed to this repo has gone through AWS Legal review to protect Amazon/Customer intellectual properties.
                To request a legal review, submit a ticket following the{' '}
                <Link
                    external
                    href="https://w.amazon.com/bin/view/Open_Source/Contributions/#HDefaultTicketProcess"
                    variant="primary"
                >
                    OSPO Default Ticket Process
                </Link>.
            </Box>
    }
}